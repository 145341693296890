import { createSlice } from "@reduxjs/toolkit";

export interface LoadingState {
    loading: boolean;
}

const initialState = {
    loading: false
} as LoadingState


const loadingSlice = createSlice({
    name: "loading state",
    initialState, 
    reducers: {
        startLoading: (state: LoadingState) => {
            state.loading = true;
        },
        stopLoading: (state: LoadingState) => {
            state.loading = false;
        }
    }
})

export const {startLoading, stopLoading} = loadingSlice.actions;

export default loadingSlice.reducer;

