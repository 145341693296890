import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export interface AuthState {
    authorized : boolean
}

const initialState  = {
    authorized: false
} as AuthState;

export const authSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers:{
        authorize: (state: AuthState) => {
                state.authorized = true   
        }, 
        unauthorize: (state: AuthState) => {
            state.authorized = false
        }
    }
}); 

export const {authorize, unauthorize} = authSlice.actions;

export const selectAutorizationStatus = (initialState: RootState) => initialState.authReducer.authorized;

export default authSlice.reducer;