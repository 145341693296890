import Header from './Header';
import { BsHouse } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks/hooks/hooks';
import { unauthorize } from '../../app/authSlice/authSlice';
import { HeaderProps } from '../types';

export const SignedHeader = ({ props }: HeaderProps) => {
  const { setAuth, home, joined, profile, className } = props;

  const dispatch = useAppDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('auth');
    setAuth(false);
    dispatch(unauthorize());
  };

  return (
    <>

      <Header props="joining more" className={className} />
      {home || profile ? (
        <Dropdown style={styles.menu}>
          <Dropdown.Toggle
            variant="dark"
            style={{
              height: 'auto',
              width: 'auto'
            }}
          >
            {home ? (
              <>
                <BsHouse size="2em" color="yellow" />
              </>
            ) : (
              <>
                {' '}
                <CgProfile size="2em" color="yellow" />
              </>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu variant="dark">
            {home ? (
              <Dropdown.Item>
                <Link to="/home" style={{ color: 'white' }}>
                  Home
                </Link>
              </Dropdown.Item>
            ) : null}

            {profile ? (
              <>
                <Dropdown.Item>
                  <Link to="/profile" style={{ color: 'white' }}>
                    Profile
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleLogout(e)}>
                  <Link to="/login" style={{ color: 'white' }}>
                    Log out
                  </Link>
                </Dropdown.Item>
              </>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      ) : null}

      {joined ? (
        <Dropdown style={styles.list}>
          <Dropdown.Toggle
            variant="dark"
            style={{
              height: 'auto',
              width: 'auto'
            }}
          >
            <AiOutlineUnorderedList size="2em" color="yellow" />
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark">
            <Dropdown.Item href="#/action-2">
              <Link to="/joined" style={{ color: 'white' }}>
                Joined events
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        ''
      )}
    </>
  );
};
const styles = {
  list: {
    position: 'relative' as const,
    float: 'left' as const,
    bottom: 57,
    left: 4
  },
  menu: {
    position: 'relative' as const,
    float: 'right' as const,
    right: 4,
    bottom: 57
  }
};
