import { useEffect, useState } from 'react';
import Header from '../ReusableComponents/Header';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { redirect } from '../ReusableComponents/Redirect';
import ReactLoading from 'react-loading';
import { useAppDispatch } from '../../app/hooks/hooks/hooks';
import { authorize } from '../../app/authSlice/authSlice';
import { url } from '../../const';

const SignUpPage = ({ setAuth }) => {
  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setErrorEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [fullname, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [renterPassword, setRenterPassword] = useState('');
  const dispatch = useAppDispatch();

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await axios
      .post(url + 'register', {
        email,
        fullname,
        username,
        password
      })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        
        console.log(err);
      });

    let authorized = false;
    console.log(resp, 'resp')
    if (resp && resp.MSSG == 'USERNAME ALREADY EXISTS!') {
      setUsernameError(true);
    } else if (resp && resp.MSSG == 'email taken') {
      setErrorEmail(true);
    } else if (!resp) {
      setServerError(true);
    } else {
      authorized = await redirect(resp, setAuth, 'User was created');
    }

    if (authorized) {
      dispatch(authorize());
      setAuth(authorized);
    }
    setLoading(false);
  };

  useEffect(() => {
    setErrorEmail(false);
    setUsernameError(false);
    setServerError(false);
  }, [email, username]);

  return (
    <div className="unauthenticated-app">
      <Header props="Join more!" />
      <div className="form">
        <ToastContainer />
        <form onSubmit={submit}>
          <div>
            <h2>Sign up</h2> {/* <-- Add this line */}
            {loading ? (
              <div className="loading">
                <ReactLoading color="yellow" height="10%" width="10%" />
              </div>
            ) : (
              ''
            )}
            {usernameError ? (
              <h6 className="error">Username is already taken!</h6>
            ) : (
              ''
            )}
            <br></br>
            {emailError ? <h6 className="error">Email already exists</h6> : ''}
            {serverError ? (
              <h6 className="error">Server Error, please try again later</h6>
            ) : (
              ''
            )}
            <br></br>
            <input
              className="input"
              value={email}
              placeholder="Email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <br></br>
            <br></br>
            <input
              className="input"
              value={fullname}
              placeholder="Full name"
              type="text"
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <br></br>
            <br></br>
            <input
              className="input"
              value={username}
              placeholder="Username"
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <br></br>
            <br></br>
            <input
              className="input"
              value={password}
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <br></br>
            <br></br>
            <input
              className="input"
              value={renterPassword}
              placeholder="Re-enter password"
              type="password"
              onChange={(e) => setRenterPassword(e.target.value)}
              required
            />
            <br></br>
            <br></br>
            <Button type="submit" variant="primary">
              Sign up
            </Button>
            <br></br>
            <br></br>
            <Link to="/login">Log in instead</Link>
            <br></br>
            <br></br>
          </div>
        </form>
        <text></text>
      </div>
    </div>
  );
};

export default SignUpPage;
