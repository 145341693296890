import { notify } from './ToastHelper';
import { toast } from 'react-toastify';

function sendNotification(msg: string) {
  return new Promise<void>((resolve) => {
    notify(msg);
    setTimeout(() => {
      resolve();
      toast.success('Logged in');
    }, 2000);
  });
}
export async function redirect(resp, setAuth, msg) {
  try {
    if (resp.token) {
      localStorage.setItem('token', resp.token);
      await sendNotification(msg);
      return true;
    } else {
      throw Error('CREDENTIAL REJECTION');
    }
  } catch (e) {
    console.log(e);
  }
}
