
import blank from '../../Resources/504-5040528_empty-profile-picture-png-transparent-png.png'
import { Assistants } from '../types';
import { Dropdown } from 'react-bootstrap';
import './AssistingUsers.scss'

export const AssistingUsers = ({ users, unjoinEvent }) => {
  const token = localStorage.getItem('token');

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="dark"
          style={{
            height: 'auto',
            width: 'auto'
          }}
        >
          <>You've Joined this event</>
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <Dropdown.Item onClick={() => unjoinEvent()}>Unjoin</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <br />
      <br />
      <p className="paragraph">Assistants</p>
      {users && users.length > 0 &&
        users.map((user: Assistants) => (
          <>
            <div className="assistant-container">
              <div className="fullname">{user.fullname}</div>
              {user.picture ? (
                <img
                  className="profile-pic"
                  src={`https://joiningmore-profile-pic.s3.amazonaws.com/profiles/${user.user_name}`}
                />
              ) : (
                <img className="profile-pic" src={blank} />
              )}
            </div>
          </>
        ))}
    </>
  );
};
