import { InputGroup } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

const SpecialInput = ({ props }) => {
  return (
    <>
      <InputGroup style={{ width: '50%', margin: 'auto' }}>
        <InputGroup.Text>{props.Fullname}</InputGroup.Text>
        <FormControl
          aria-label="Full name"
          placeholder={props.props}
          name={props.name}
          value={props.props}
          onChange={props.change}
        />
      </InputGroup>
    </>
  );
};

const FileForm = () => {
  return (
    <Form.Group controlId="formFile">
      <Form.Control type="file" style={{ width: '50%', margin: 'auto' }} />
    </Form.Group>
  );
};

export default { SpecialInput, FileForm };
