import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileInfo } from '../../Pages/types';
import { RootState } from '../store';

export interface ProfileState {
  user_name: string;
  dateofbirth: string;
  fullname: string;
  picture: string;
  user_email: string;
  profilePicture: string;
  events: string[];
}

const initialState = {
  user_name: undefined,
  dateofbirth: undefined,
  fullname: undefined,
  picture: undefined,
  user_email: undefined,
  profilePicture: undefined,
  events: []
} as ProfileState;

const profileSlice = createSlice({
  name: 'profile state',
  initialState,
  reducers: {
    loadProfile: (state: ProfileState, action: PayloadAction<ProfileInfo>) => {
      state.user_name = action.payload.user_name;
      state.dateofbirth = action.payload.dateofbirth;
      state.fullname = action.payload.fullname;
      state.user_email = action.payload.user_email;
      state.picture = action.payload.picture;
      state.events = action.payload.events;
    },
    loadImage: (state: ProfileState, action: PayloadAction<string>) => {
      state.profilePicture = action.payload;
    }
  }
});

export const { loadProfile, loadImage } = profileSlice.actions;

export default profileSlice.reducer;
export const selectProfileStatus = (initialState: RootState) =>
  initialState.profileReducer;
