import { useEffect, useMemo, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import axios, { AxiosResponse } from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router';
import { url } from '../../const';
import './forgot-password.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { SignedHeader } from '../ReusableComponents/SignedHeader';
import './forgot-password.scss';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

const ForgotPassword = ({ setAuth }) => {
  let { user_name } = useSelector((state: RootState) => state.profileReducer);

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [authenticated, setAuthenticated] = useState(setAuth);
  const [correctCode, setCorrectCode] = useState(false);

  const [password, setPassword] = useState({
    newPassword: '',
    secondPassword: ''
  });
  const token = localStorage.getItem('token');
  const location = useLocation();
  useEffect(() => {
    setError(false);
  }, [password]);

  //
  const props = {
    setAuth: setAuth,
    home: authenticated,
    joined: authenticated,
    profile: authenticated, 
    className: 'forgot-password'
  };

  useEffect(() => {
    if (user_name) {
      setAuthenticated(true);
    }
  }, [user_name]);

  useEffect(() => {
    setCorrectCode(false);
  }, [location.key, location.hash, location.state]);

  const { newPassword, secondPassword } = password;
  const emailSubmit = async () => {
    if (email) {
      const response: any = await axios
        .post(url + '-forgot-password-email', { email })
        .catch((e) => {
          console.log(e);
        });
      console.log(response, 'response');
      if (response && response.status == 200) {
        setMessageSent(true);
      }
    }
  };
  const codeSubmit = async () => {
    if (code.length > 3) {
      const response: any = await axios
        .post(url + '/-reset-code', { userResetCode: code, email })
        .catch((e) => {
          console.log(e);
        });

      if (response && response.status == 200) {
        setCorrectCode(true);
      } else {
        toast.error('Wrong code, please try again');
        setCorrectCode(false);
      }
    }
  };
  const passwordSubmit = async (e) => {
    if (newPassword == secondPassword && newPassword.length > 3) {
      setError(false);
      e.preventDefault();
      //AXIOS REQUEST TO CHANGE PASSWORDS
      const response = await axios
        .post(url + '-new-password', {
          newPassword,
          user_name,
          user_email: email
        })
        .catch((e) => {
          console.log(e);
        });

      toast.success('Password changed succesfully!');

      new Promise(() => {
        setTimeout(() => {
          navigate('/login');
        }, 1500);
      });
    } else {
      setError(true);
    }
  };

  const passwordErrorStyle = error ? 'password-error-message' : '';

  const authentication = useMemo(() => {
    return (authenticated && token) || correctCode;
  }, [authenticated, correctCode, location]);

  return (
    <>
      <ToastContainer />
      <div className="forgot-page">
        <SignedHeader props={{ ...props }} />
        <div className="form-container">
          {authentication ? (
            <>
              <p className="enter-password">Let's set a new password!</p>
              <InputGroup className="input-group">
                {error ? (
                  <p className={passwordErrorStyle}>
                    Oops! Passwords don't match.
                  </p>
                ) : null}
                <InputGroup.Text>Choose a new password</InputGroup.Text>
                <FormControl
                  value={newPassword}
                  name="newPassword"
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      [e.target.name]: e.target.value
                    })
                  }
                  placeholder="Enter new password"
                  type="password"
                />
              </InputGroup>
              <InputGroup className="input-group">
                <InputGroup.Text>Confirm your new password</InputGroup.Text>
                <FormControl
                  value={secondPassword}
                  name="secondPassword"
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      [e.target.name]: e.target.value
                    })
                  }
                  placeholder="Re-enter new password"
                  type="password"
                />
              </InputGroup>
              <Button onClick={passwordSubmit} variant="dark" type="submit">
                Reset Password
              </Button>
            </>
          ) : null}
          {!authentication && !messageSent ? (
            <>
              <p className="reset-password-title">
                Let's start by confirming your email
              </p>

              <InputGroup className="input-group">
                <InputGroup.Text>Email</InputGroup.Text>
                <FormControl
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="your-email@example.com"
                />
                <Button onClick={emailSubmit} variant="dark" type="submit">
                  Confirm Email
                </Button>
              </InputGroup>
            </>
          ) : null}
          {!authentication && messageSent ? (
            <>
              <p style={{ fontWeight: 'bold' }}>
              A confirmation code has been sent to your email address (if already registered). Please check your email and enter the code below.</p>
              <InputGroup className="input-group">
                <InputGroup.Text>Code</InputGroup.Text>
                <FormControl
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter the code"
                />
                <Button onClick={codeSubmit} variant="dark" type="submit">
                  Verify Code
                </Button>
              </InputGroup>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
