import { SignedHeader } from '../ReusableComponents/SignedHeader';
import { toast, ToastContainer } from 'react-toastify';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import helper from './EditProfileHelper';
import { Form } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import 'dotenv/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { loadImage, loadProfile } from '../../app/profileSlice/profileSlice';
import { useNavigate } from 'react-router';
import { url } from '../../const';
import './edit-profile.scss';

const EditProfile = ({ setAuth }: any) => {
  let profileInfo = useSelector((state: RootState) => state.profileReducer);
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectImage = useSelector(
    (state: RootState) => state.profileReducer.profilePicture
  );
  const isLoading = useSelector(
    (state: RootState) => state.loadingReducer.loading
  );
  const [load, setLoad] = useState(false);
  const { SpecialInput } = helper;
  const props = {
    setAuth: setAuth,
    home: true,
    joined: true,
    profile: true,
    className: 'editProfileApp'
  };
  const [file, setFile] = useState({
    file: null
  });
  const [input, setInput] = useState({
    fullname: profileInfo.fullname,
    username: profileInfo.user_name,
    email: profileInfo.user_email,
    dateofbirth: profileInfo.dateofbirth,
    picture: profileInfo.picture,
    events: profileInfo.events
  });
  const onchange = (e: any) => {
    e.preventDefault();
    setFile({ file: e.target.files[0] });
  };

  let { fullname, username, email, dateofbirth, picture } = input;
  useEffect(() => {}, []);
  //SEND PICTURE TRUE when sending image.
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file.file !== null) {
      picture = 'true';
      const formData = new FormData();
      formData.append(username, file.file);
      //Make new API CALL
      await axios
        .post(
          url +
            '-profile-image?fileName=' +
            username +
            '&bucketName=' +
            'joiningmore-profile-pic',
          null,
          {
            headers: {
              Authorization: `${token}`
            }
          }
        )
        .then((response) => {
          // Getting the url from response
          const url = response.data.fileUploadURL;
          let data = file.file;
          axios
            .put(url, data, {
              headers: { 'Content-Type': 'multipart/form-data' }
            })
            .catch((e) => {
              console.log(e);
            })
            .then(() => {
              dispatch(
                loadImage(
                  `${'https://joiningmore-profile-pic.s3.amazonaws.com/profiles/'}${username}?avoidCache=${Math.floor(
                    Math.random() * 3324
                  )}`
                )
              );
            })
            .catch((e) => {
              console.log('Could not upload picture');
            });
        });
    }

    let infoToUpdate = { fullname, username, email, dateofbirth, picture };

    const responseInfo = await axios
      .post(url + 'profile', infoToUpdate, {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((result) => result.data)
      .catch((err) => {
        console.log(err);
      });
    if (responseInfo === 'true') {
      toast.success('Information succesfully updated');
      dispatch(
        loadProfile({
          user_email: input.email,
          user_name: input.username,
          dateofbirth: input.dateofbirth,
          fullname: input.fullname,
          picture: input.picture,
          events: input.events
        })
      );

      setTimeout(() => {
        navigate('/profile');
      }, 2000);
    }
  };

  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setInput({ ...input, [e.target.name]: value });
  };

  return (
    <>
      <ToastContainer />
      <main className="editProfileApp">
        <SignedHeader props={props} />
        <h1>Edit profile</h1>
        {isLoading ? (
          <div role="status" aria-live="polite" className="loadingContainer">
            <ReactLoading color={'yellow'}  />
            <p className="visually-hidden">Loading...</p>
          </div>
        ) : (
          <>
   
            <section className="profileSection">
              <form onSubmit={(e) => submit(e)}>
                <fieldset>
                  <legend>Personal Information</legend>
                  <div className="inputGroup">
                    <SpecialInput
                      props={{
                        Fullname: 'Full Name',
                        props: fullname || profileInfo.fullname,
                        change: change,
                        name: 'fullname'
                      }}
                    />
                    <div className="inputGroup">
                      <SpecialInput
                        props={{
                          Fullname: 'Email',
                          props: email || profileInfo.user_email,
                          change: change,
                          name: 'email'
                        }}
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset className="profilePictureSection">
                  <legend>Profile picture</legend>
                  {file.file ? (
                    <img
                      src={URL.createObjectURL(file.file)}
                      className="profilePicture"
                      alt="Selected profile picture"
                    />
                  ) : (
                    <div className="smoothImageWrapper">
                      <label>
                        <img
                          className={`profilePicture image-${load ? 'visible' : 'hidden'}`}
                          src={selectImage}
                          alt="Current profile picture"
                          onLoad={() => setLoad(true)}
                        />
                        {!load && (
                          <div className="smooth-preloader">
                            <div className="loadingContainer">
                              <ReactLoading color={'yellow'} />
                            </div>
                          </div>
                        )}
                      </label>
                      <Form.Group controlId="formFile">
                        <Form.Label>Choose a new picture:</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={(e) => onchange(e)}
                          className="fileInput"
                        />
                      </Form.Group>
                    </div>
                  )}
                </fieldset>
                <Button variant="dark" type="submit">
                  Submit
                </Button>
              </form>
              <div className="forgot-password">
                <Link className="passwordChangeLink" to="/forgot">
                  Change password
                </Link>
              </div>
            </section>
          </>
        )}
      </main>
    </>
  );

};

/***************************HOME PAGE CSS**********************************/


export default EditProfile;
