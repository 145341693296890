import { EventType } from '../types';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';

import './event-container.scss';

const EventContainer = (event: EventType) => {
  return (
    <div className={classNames('event', event.origin)}>
      <Row>
        <Col>
          <div className="event-container">
            <p className="title">{event.title}</p>
            <p className="date">{event.date}</p>
            <p className="description">
            {event.description ?? (
             event.description
            )}
            </p>
            <p className="location">{event.address}</p>
            <p className="time">Time: {event.time}</p>
            <p className="cost">Cost: {event.free ? 'Free' : event.cost}</p>
          </div>
        </Col>
        <Col>
          <div>
            <img
              className="event-image"
              alt="IMAGES"
              src={`${'https://event-images-container.s3.amazonaws.com/'}${
                event.uuid
              }/eventImage1`}
            ></img>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EventContainer;


