import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './Pages/Home/Home';
import LoginPage from './Pages/Login/LoginPage';
import SignUpPage from './Pages/Signup/SignUpPage';
import ProfilePage from './Pages/Profile/Profile';
import { useEffect, useState } from 'react';
import JoinedEvents from './Pages/JoinedEvents.js/JoinedEvents';
import EditProfile from './Pages/EditProfile.js/EditProfile';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Version from './Pages/Version/Version';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { loadImage, loadProfile } from './app/profileSlice/profileSlice';
import { startLoading, stopLoading } from './app/loadingSlice/loading';
import blank from './Resources/504-5040528_empty-profile-picture-png-transparent-png.png';
import { CompleteEvent } from './Pages/Event/CompleteEvent';
import { url } from './const';
import { ProfileInfo } from './Pages/types';

const App = () => {
  const dispatch = useDispatch();

  const [authenticated, setAutheticated] = useState(false);
  const setAuth = (boolean) => {
    setAutheticated(boolean);
  };

  // Inside your component
  const location = useLocation();

  const checkAuthentication = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(url + 'verify', {
        headers: {
          Authorization: `${token}`
        }
      });
      if (response) {
        localStorage.setItem('auth', 'true');
        const response: ProfileInfo = await axios
          .get(url + 'profile', {
            headers: {
              Authorization: `${token}`
            }
          })
          .then((result) => result.data)
          .catch((err) => {
            console.log(err);
          });

        dispatch(loadProfile(response));
        let picture: string;
        if (response.picture) {
          picture = `https://joiningmore-profile-pic.s3.amazonaws.com/profiles/${
            response.user_name
          }?avoidCache=${Math.floor(Math.random() * 3324)}`;
        } else {
          picture = blank;
        }
        dispatch(loadImage(picture));
        setAuth(true);
      } else {
        localStorage.removeItem('auth');
        setAuth(false);
      }

      // return parseRes
    } catch (err) {
      setAuth(false);
      localStorage.removeItem('token');
    }
  };

  const reload = async () => {
    await checkAuthentication();
    dispatch(stopLoading());
  };

  useEffect(() => {
    reload();
  }, [localStorage.getItem('token'), location]);

  useEffect(() => {
    dispatch(startLoading());
    if (PerformanceNavigationTiming) {
      if (localStorage.getItem('auth')) {
        setAuth(true);
      } else {
        setAuth(false);
        localStorage.removeItem('token');
      }
      reload();
    } else {
      console.log('page is not refreshed');
    }
  }, []);

  return (
    <Routes>
      {/* event container */}
      <Route
        path="/:eventId"
        element={
          authenticated ? (
            <CompleteEvent setAuth={setAuth} />
          ) : (
            <LoginPage setAuth={setAuth} />
          )
        }
      />

      {/* Login Path */}
      <Route
        path="/login"
        element={
          authenticated ? (
            <Home setAuth={setAuth} />
          ) : (
            <LoginPage setAuth={setAuth} />
          )
        }
      />
      {/* Sign up path */}
      <Route
        path="/signup"
        element={
          authenticated ? (
            <Home setAuth={setAuth} />
          ) : (
            <SignUpPage setAuth={setAuth} />
          )
        }
      />
      {/* Home path */}
      <Route
        path="/home"
        element={
          authenticated ? (
            <Home setAuth={setAuth} />
          ) : (
            <LoginPage setAuth={setAuth} />
          )
        }
      />
      {/* Profile */}
      <Route
        path="/profile"
        element={
          authenticated ? (
            <ProfilePage setAuth={setAuth} />
          ) : (
            <LoginPage setAuth={setAuth} />
          )
        }
      />
      {/* Edit Profile */}
      <Route
        path="/edit-profile"
        element={
          authenticated ? (
            <EditProfile setAuth={setAuth} />
          ) : (
            <LoginPage setAuth={setAuth} />
          )
        }
      />
      {/* Joined Events */}
      <Route
        path="/joined"
        element={
          authenticated ? (
            <JoinedEvents setAuth={setAuth} />
          ) : (
            <LoginPage setAuth={setAuth} />
          )
        }
      />
      {/* Forgot Password */}
      <Route path="/forgot" element={<ForgotPassword setAuth={setAuth} />} />
      {/* Forgot Password */}
      <Route path="/version" element={<Version />} />

      {/* Default path */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default App;
