import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SignedHeader } from '../ReusableComponents/SignedHeader';
import { Assistants, EventType, ProfileInfo } from '../types';
import { Row, Col, Button } from 'react-bootstrap';
import './complete-event.scss';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { url } from '../../const';
import { AssistingUsers } from './AssistingUsers';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const containerStyle = {
  width: '400px',
  height: '400px'
};

export const CompleteEvent = ({ setAuth }) => {
  const token = localStorage.getItem('token');
  let profileInfo = useSelector((state: RootState) => state.profileReducer);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB2arAIvfToKIb9WVU0fz87eAn3MtvMGpY'
  });
  let geocoder = new window.google.maps.Geocoder();

  const [center, setCenter] = useState<any>();
  const { eventId } = useParams();

  const [isMember, setIsMember] = useState(false);
  const [event, setEvent] = useState<EventType>(undefined);
  const [users, setUsers] = useState<Assistants[] | []>([]);

  const addUsertoEvent = async () => {
    const user = profileInfo.user_name;
    const response = await axios.post(url + 'adduser', { user, eventId });
    if (!response.status.toString().includes('4')) {
      setIsMember(true);
    }
  };

  const fetchUsers = async (user_names: ProfileInfo[]) => {
    const response = await axios
      .post(url + '-user-assistants', user_names, {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((response) => response.data)
      .catch((e) => console.log(e));
    response && response.users&& setUsers(response.users);
  };

  const unjoinEvent = async () => {
    const user = profileInfo.user_name;
    const response = await axios.post(url + '-unjoin-event', {
      user,
      eventId
    });
    if (!response.status.toString().includes('4')) {
      setIsMember(false);
      toast.info('Unjoined');
    }
  };

  const fetchEvent = async () => {
    const eventResponse = await axios
      .get(url + `-event-path?uuid=${eventId}`, {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((data) => data);
    setEvent(eventResponse.data.Item);
  };

  useEffect(() => {
    event && event.users && fetchUsers(event.users);
  }, [event, isMember]);

  const EventMap = () => (
    <GoogleMap zoom={10} center={center} mapContainerStyle={containerStyle}>
      <Marker position={center} />
    </GoogleMap>
  );

  const setCenterMap = async () => {
    geocoder.geocode(
      {
        address: event?.address || ''
      },
      (result) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        result && setCenter(result[0].geometry.location);
      }
    );
  };

  useEffect(() => {
    fetchEvent();
  }, [isLoaded, isMember]);

  useEffect(() => {
    profileInfo.events && setIsMember(profileInfo.events.includes(eventId));
  }, [profileInfo.events]);

  useEffect(() => {
    setCenterMap();
  }, [event]);

  return !event ? (
    <h1>loading ...</h1>
  ) : (
    <div className="complete-app">
      <ToastContainer />

      <SignedHeader
        props={{ setAuth, joined: true, home: true, profile: true, className: 'complete-event' }}
      />
      <div className="complete-event">
        <Row>
          <Col>
            <div className="complete-event-container">
              <p className="complete-title">{event.title}</p>
              <p className="complete-date">{event.date}</p>
              <p className="complete-description">{event.description}</p>
              <p className="complete-location">{event.address}</p>
              <p className="complete-time">Time: {event.time}</p>
              <p className="complete-cost">
                Cost: {event.free ? 'Free' : event.cost}
              </p>
            </div>
          </Col>
          <Col>
            <div>
              <img
                className="complete-event-image"
                alt="IMAGES"
                src={`${'https://event-images-container.s3.amazonaws.com/'}${
                  event.uuid
                }/eventImage1`}
              ></img>
            </div>
          </Col>
        </Row>
      </div>
      {isLoaded && geocoder ? (
        <div className="map">
          <EventMap /> <br />
          {!isMember ? (
            <Button variant="warning" onClick={addUsertoEvent}>
              Join
            </Button>
          ) : (
            <AssistingUsers users={users || []} unjoinEvent={unjoinEvent} />
          )}
        </div>
      ) : (
        <h1>.... Loading</h1>
      )}
    </div>
  );
};
