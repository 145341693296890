const Header = (props: any, classname: string) => {
  return (
    // implment css for this
    <div className={classname}>
      <h1 style={styles.headers}>{props.props}</h1>
    </div>
  );
};

const styles = {
  headers: {
    color: 'yellow',
    backgroundColor: 'black',
    fontWeight: 'bold',
    borderRadius: '10px',
    height: '55px'
  }
};
export default Header;
