import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../ReusableComponents/Header';
import axios from 'axios';
import { redirect } from '../ReusableComponents/Redirect';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch } from '../../app/hooks/hooks/hooks';
import { authorize } from '../../app/authSlice/authSlice';
import { url } from '../../const';
import './loginPage.scss';

const LoginPage = ({ setAuth }) => {
  const dispatch = useAppDispatch();
  let response: any;
  const [userInput, setUserInput] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const submit = async (e) => {
    e.preventDefault();
    // get our form data out of state
    let email = '';
    let username = '';
    //check if its a email or a username
    userInput.endsWith('.com') ? (email = userInput) : (username = userInput);

    if (email) {
      response = await axios
        .post(url + 'login', { user_email: email, user_password: password })
        .then((result) => result.data)
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    } else {
      response = await axios
        .post(url + 'login', { user_name: username, user_password: password })
        .then((result) => result.data)
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    }
    let authorized = await redirect(response, setAuth, 'Logged in!');
    if (authorized) {
      dispatch(authorize());
      setAuth(authorized);
    }
  };
  useEffect(() => {
    setError(false);
  }, [userInput, password]);

  return (
    <div className="unauthenticated-app">
      <ToastContainer />
      <Header props="Joining more" />
      <div className="form">
        <form onSubmit={submit}>
          <div>
            <br></br>
            {error ? (
              <>
                <h6>Credentials invalid</h6>
              </>
            ) : (
              ''
            )}
            <br></br>
            <input
              value={userInput}
              placeholder="email or username"
              type="text"
              onChange={(e) => setUserInput(e.target.value)}
            />
            <br></br>
            <br></br>
            <input
              value={password}
              placeholder="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <br></br>
            <br></br>
            <Button type="submit" variant="primary">
              Log in
            </Button>
            <br></br>
            <br></br>
            <Link to="/forgot"> Forgot password</Link>
            <br></br>
            <br></br>
            <br></br>
            Dont have an account? <Link to="/signup"> Sign up</Link>
            <br></br>
          </div>
        </form>
      </div>

      <br />
    </div>
  );
};
/***************************LOGIN PAGE CSS**********************************/
/*Header*/

export default LoginPage;
