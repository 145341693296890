import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { SignedHeader } from '../ReusableComponents/SignedHeader';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import EventContainer from './EventContainer';
import './home.scss';
import { EventType } from '../types';
import { useNavigate } from 'react-router-dom';
import { url } from '../../const';

const Home = ({ setAuth }) => {
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const token = `${localStorage.getItem('token')}`;
  const getEvents = async () => {
    const eventResponse = await axios
      .get(url + '-all-events', {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((res) => res.data.events?.Items)
      .catch((e) => console.log(e));

    await axios
      .get(url + 'profile', {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((result) => result.data)
      .catch((err) => {
        console.log(err);
      });
    setEvents(eventResponse);
  };

  const searchEvents = async (e) => {
    e.preventDefault();
    const eventResponse = await axios
      //missing gateway addition. searching dynamo as well with contains
      .get(url + `-search-event?searchTerm=${searchTerm}`, {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((res) => res.data.Items || []);

    setEvents(eventResponse);
  };

  const addSearchTerm = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      searchEvents(e);
    }

    setSearchTerm(e.target.value);
  };

  const goToContainer = (event: EventType) => {
    navigate(`/${event.uuid}`);
  };

  useEffect(() => {
    getEvents();
  }, []);

  const props = {
    setAuth: setAuth,
    home: false,
    joined: true,
    profile: true, 
    className: 'home-app'
  };

  return (
    <div className="home-app">
      <SignedHeader props={props} />
      <div style={{ fontWeight: 'bold' }}>Exclusively in New Braunfels</div>
      <br />
      <Form className="d-flex">
        <Form.Control
          onChange={(e) => addSearchTerm(e)}
          onKeyDown={(e) => addSearchTerm(e)}
          type="search"
          placeholder="Soccer, cards ...."
          className="me-2"
          aria-label="Search"
        />
        <Button
          variant="outline-success"
          onClick={(e) => searchEvents(e)}
          onSubmit={(e) => searchEvents(e)}
        >
          Search
        </Button>
      </Form>
      <br></br>
      
      <Container>
        <Row>
          {events.length > 0 ? (
            events.sort().map((event: EventType) => {
              return (
                <>
              <Col>
                  <Navbar
                    variant="dark"
                    onClick={() => goToContainer({ ...event })}
                  >
                    <Nav.Link>
                      <EventContainer {...event} />
                    </Nav.Link>
                  </Navbar>
                </Col>
                </>
              );
            })
          ) : (
            <>
              <br />
              <h4>No events found, try searching something else</h4>
              <br />
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

/***************************HOME PAGE CSS**********************************/

export default Home;
