import { SignedHeader } from '../ReusableComponents/SignedHeader';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import './profile.scss';

const Profile = ({ setAuth }) => {
  const isLoading = useSelector((state: RootState) => state.loadingReducer.loading);
  const [imageLoaded, setImageLoaded] = useState(false);
  const profileInformation = useSelector((state: RootState) => state.profileReducer);
  const navigate = useNavigate();

  const {
    dateofbirth,
    fullname,
    user_email,
    user_name,
    picture,
    profilePicture
  } = profileInformation;

  const headerProps = {
    setAuth: setAuth,
    home: true,
    joined: true,
    profile: true,
    className: 'profile-header'
  };

  return (
    <div className='app-profile'>
      <SignedHeader props={headerProps} />
      
      <h2 className='profileTitle'>Profile</h2>

      {isLoading ? (
        <div className='loader'>
          <ReactLoading color={'yellow'} />
        </div>
      ) : (
        <>
          <div className='profileSection'>
            <div className='profilePictureSection'>
              <h4 className='greeting'>Hello, {user_name}!</h4>
              <div className='profilePictureWrapper'>
                <img
                  src={profilePicture}
                  className={`profilePicture image-${imageLoaded ? 'visible' : 'hidden'}`}
                  onLoad={() => setImageLoaded(true)}
                />
                {!imageLoaded && <div className='preloader'><ReactLoading color={'yellow'} /></div>}
              </div>
            </div>

            <div className='profileDetailsSection'>
              <div className='profileDetail'>
                <h5 className='detailTitle'>Full name:</h5>
                <p className='detailValue'>{fullname}</p>
              </div>
              <div className='profileDetail'>
                <h5 className='detailTitle'>Email:</h5>
                <p className='detailValue'>{user_email}</p>
              </div>
              <div className='profileDetail'>
                <h5 className='detailTitle'>Username:</h5>
                <p className='detailValue'>{user_name}</p>
              </div>
            </div>
          </div>

          <div className='profileActions'>
            <Button variant="dark" onClick={() => navigate('/edit-profile')}>Edit Profile</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
