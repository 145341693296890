import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { SignedHeader } from '../ReusableComponents/SignedHeader';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { url } from '../../const';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { EventType } from '../types';
import EventContainer from '../Home/EventContainer';
import { useNavigate } from 'react-router';
import './joinedEvents.scss';

const JoinedEvents = ({ setAuth }) => {
  let profileInfo = useSelector((state: RootState) => state.profileReducer);
  const [eventsID, setEventsID] = useState([]);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const props = {
    setAuth: setAuth,
    home: true,
    joined: false,
    profile: true,
    className: 'joined-header'
  };
  const goToContainer = (event: EventType) => {
    navigate(`/${event.uuid}`);
  };
  const getEvents = async () => {
    const token = localStorage.getItem('token');

    const response = await axios
      .post(url + '-user-events', eventsID, {
        headers: {
          Authorization: `${token}`
        }
      })
      .then((response) => response.data)
      .catch((e) => console.log(e));

    if (response) {
      setEvents(response.events);
    }
  };

  useEffect(() => {
    setEventsID(profileInfo.events);
  }, [profileInfo.events]);

  useEffect(() => {
    getEvents();
  }, [eventsID]);

  return (
    <div className='joined-app'>
      <div className="joined-header">
        <SignedHeader props={props} />
      </div>
      <Container>
        <Row>
          {events && events.length > 0 ? (
            events.map((event: EventType) => {
              const eventInformation = {
                ...event,
                description: undefined,
                origin: 'joined'
              };
              return (
                <Col>
                  <Navbar
                    variant="dark"
                    onClick={() => goToContainer({ ...event })}
                  >
                    <Nav.Link>
                      <EventContainer {...eventInformation} />
                    </Nav.Link>
                  </Navbar>
                </Col>
              );
            })
          ) : (
            <>
              <br />
              <h4>You haven't joined any events yet! </h4>
              <br />
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default JoinedEvents;

/***************************Joined Events CSS**********************************/

