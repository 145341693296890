import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice/authSlice';
import profileReducer from './profileSlice/profileSlice';
import loadingReducer from './loadingSlice/loading';
export const store = configureStore({
  reducer: {
    authReducer,
    profileReducer,
    loadingReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
